import React, { useEffect, useState } from 'react';

import {
  RouteComponentProps,
  useLocation,
  WindowLocation,
} from '@reach/router';
import _ from 'lodash';

import { DragAndDropContainers } from '@components/DragAndDropContainers';
import { UNASSIGNED } from '@components/DragAndDropContainers/constants/groupnames';
import { Group } from '@components/DragAndDropContainers/types';
import {
  areItemsInGroup,
  isNotUnassigned,
} from '@components/DragAndDropContainers/utils';
import LinkPrevious from '@components/LinkPrevious';
import Loading from '@components/Loading';
import PageSteps from '@components/PageSteps';
import Title from '@components/Title';
import { titleTemplateParams } from '@components/Title/util';
import SaveChangesModal from '@presenters/web/components/SaveChangesModal';
// import SelectTerm from '@presenters/web/components/SelectTerm';

import { AVAILABLE_TERMS_MODAL, PRIOR_YEAR_MODAL } from '@domain/districts';
import { MovedItems, TermToAssign } from '@domain/districts/types';

import { getDistrictIdFromPath } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

// import { useMoveAgClubAffiliations } from '@repositories/districts';

// import { pushLocalisedUrl } from '@utils/browserHistory';
// import { getCurrentDate } from '@utils/datetime';
import { localizedNavigate } from '@utils/localized-navigate';

import { getFormattedData } from '@domui-domain/districts/mappers';
import { useAllActiveClubsByDistrictAndRotaryYearrequestData } from '@domui-hooks/useAllActiveClubsByDistrictAndRotaryYearrequestData';
import { useAssistantDistrictGovernorsWithAssignedClubs } from '@domui-hooks/useAssistantDistrictGovernorsWithAssignedClubs';
import { usePrevYearAssistantDistrictGovernorsWithAssignedClubs } from '@domui-hooks/usePrevYearAssistantDistrictGovernorsWithAssignedClubs';
import { useUpdateClubAssignmentsForAssistantGovernors } from '@domui-hooks/useUpdateClubAssignmentsForAssistantGovernors';
import {
  buildAGsPageUrl,
  excludePrevAGCurrentAgClubs,
  getClubAssignmentsModalLabels,
  getLabelValue,
  getTermStringFromObject,
  // isCurrentTerm,
  normalizeAssignedAG,
  normalizeUnassignedClub,
  useModal,
  useStopBrowserNavigate,
} from '@domui-use-cases/districts';
import { hasActiveAgClubs } from '@domui-utils/hasActiveAgClubs';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

interface Destination {
  districtPk: string;
  assistantGovernors: Array<{
    individualPk: string;
    clubs: Array<{
      clubId: string;
      action: string;
    }>;
  }>;
  clubReplace: Array<{
    clubId: string;
    existingIndividualPk: string;
    newIndividualPk: string;
  }>;
}

interface Props extends RouteComponentProps {
  districtId: string;
  termsToAssign: TermToAssign[];
}

const ClubAssignments: React.FC<Props> = ({ districtId, termsToAssign }) => {
  const [clubsData, setClubsData] = useState<Group[]>([]);
  const [prevClubsData, setPrevClubsData] = useState<Group[]>([]);
  const [movedItems, setMovedItems] = useState<MovedItems[]>([]);

  const [isPriorYearClicked, setIsPriorYearClicked] = useState<boolean>(false);
  const [isCheckedPriorYearGroup, setIsCheckedPriorYearGroup] = useState<
    boolean
  >(false);
  const [isOpenedPriorYearModal, setIsOpenedPriorYearModal] = useState<boolean>(
    false
  );

  const [isAvailableTermClicked, setIsAvailableTermClicked] = useState<boolean>(
    false
  );
  const [isOpenedAvailableTermModal, setIsOpenedAvailableTermModal] = useState<
    boolean
  >(false);

  const areClubsMoved = !!movedItems.length;

  const { isShowing, show } = useModal(window.stopBrowserNavigate);

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const { t } = useTranslation();
  const location = useLocation();
  const dominoDistrictId = getDistrictIdFromPath(location?.pathname);
  const districtID = districtId || dominoDistrictId;
  const { addError, addSuccess } = useNotifications();

  const getStepPageSettings = (routerLocation: WindowLocation) => {
    const { state } = routerLocation;
    const { stepsCount, step, term } =
      (state as Record<string, string | TermToAssign>) || {};
    if (stepsCount && step) {
      return {
        stepsCount: Number(stepsCount),
        step: Number(step),
        selectedInfoTerm: term,
      };
    }
    return null;
  };

  const isStepPage = (routerLocation: WindowLocation) =>
    Boolean(getStepPageSettings(routerLocation));

  const {
    stepsCount: total,
    step: current,
    // selectedInfoTerm
  } = getStepPageSettings(location) || {};

  // const [
  //   moveAgClubAffiliations,
  //   { loading: moveLoading },
  // ] = useMoveAgClubAffiliations();

  const { prefix, suffix } = titleTemplateParams(t);

  const agsPageUrl = buildAGsPageUrl(districtID);
  const isShowStepPage = isStepPage(location);

  // const locationStateBackUrl =
  //   (location?.pathname as string)?.backUrl || agsPageUrl;

  // const [selectedTerm] = useState<TermToAssign>(
  //   isShowStepPage ? (selectedInfoTerm as TermToAssign) : termsToAssign[0]
  // );

  // const [changedSelectedTerm, setChangedSelectedTerm] = useState<TermToAssign>(
  //   selectedTerm
  // );

  const {
    loading: moveLoading,
    error,
    clubAssignmentsForAssistantGovernors,
  } = useUpdateClubAssignmentsForAssistantGovernors();

  useErrorHandling(
    error?.description
      ? error.description
      : t(
          'manage-club-assignments.save-assigned-clubs.error',
          'There was an error encountered, please try again later'
        ),
    !!error,
    'manage-club-assignments.save-assigned-clubs.error'
  );

  const {
    data: districtClubsData,
    loading: districtClubsLoading,
    fetchAllActiveClubsByDistrictAndRotaryYearrequestData,
  } = useAllActiveClubsByDistrictAndRotaryYearrequestData();

  const {
    data: agsResultData,
    loading: loadingAGsByDate,
    assistantDistrictGovernorsWithAssignedClubs,
  } = useAssistantDistrictGovernorsWithAssignedClubs();

  const {
    data: agsPrevYearResultData,
    loading: loadingPrevAGsByDate,
    prevYearAssistantDistrictGovernorsWithAssignedClubs,
  } = usePrevYearAssistantDistrictGovernorsWithAssignedClubs();

  useEffect(() => {
    const { startDate } = termsToAssign[1];
    if (districtID) {
      const allActiveClubsByDistrictPayload = {
        organizationId: districtID,
        rotaryYear: startDate,
      };
      fetchAllActiveClubsByDistrictAndRotaryYearrequestData(
        allActiveClubsByDistrictPayload
      );
    }
  }, [districtID]);

  useEffect(() => {
    const { startDate } = termsToAssign[1];
    if (districtID) {
      const assignedClubsPayload = {
        organizationId: districtID,
        rotaryYear: startDate,
        isPaginated: false,
        page: 0,
        pageSize: 0,
      };
      assistantDistrictGovernorsWithAssignedClubs(assignedClubsPayload);
    }
  }, [districtID]);

  useEffect(() => {
    const { startDate } = termsToAssign[0];
    if (districtID) {
      const prevYearAssinedClubPayload = {
        organizationId: districtID,
        rotaryYear: startDate,
        isPaginated: false,
        page: 0,
        pageSize: 0,
      };
      prevYearAssistantDistrictGovernorsWithAssignedClubs(
        prevYearAssinedClubPayload
      );
    }
  }, [districtID]);

  useEffect(() => {
    if (agsResultData && districtClubsData) {
      const unassignedClubs = normalizeUnassignedClub(districtClubsData);
      const assignedAGs = normalizeAssignedAG(agsResultData);
      setClubsData([unassignedClubs, ...assignedAGs]);
    }
  }, [setClubsData, agsResultData, districtClubsData]);

  useEffect(() => {
    const unassignedClubs = normalizeUnassignedClub(districtClubsData);
    const assignedAGs = normalizeAssignedAG(agsResultData);
    if (agsPrevYearResultData && isCheckedPriorYearGroup) {
      const assignedPrevYearAGs: Group[] = normalizeAssignedAG(
        agsPrevYearResultData,
        UNASSIGNED
      );
      const prevData = excludePrevAGCurrentAgClubs(
        assignedAGs,
        assignedPrevYearAGs
      )
        .filter(areItemsInGroup)
        .map(data => ({
          ...data,
          items: data?.items?.filter(({ id }) =>
            hasActiveAgClubs(id, districtClubsData)
          ),
        }));
      const unassignedExcludePrior = excludePrevAGCurrentAgClubs(
        assignedPrevYearAGs,
        [unassignedClubs]
      );
      setPrevClubsData(prevData);
      setClubsData(prevState => [
        ...prevState.filter(isNotUnassigned),
        ...unassignedExcludePrior,
      ]);
    } else {
      setPrevClubsData([]);
      setClubsData(prevState => [
        ...prevState.filter(isNotUnassigned),
        unassignedClubs,
      ]);
    }
  }, [
    setClubsData,
    setPrevClubsData,
    agsPrevYearResultData,
    agsResultData,
    districtClubsData,
    isCheckedPriorYearGroup,
  ]);

  if (districtClubsLoading || loadingAGsByDate || loadingPrevAGsByDate) {
    return <Loading />;
  }

  // const startDate = isCurrentTerm(selectedTerm)
  //   ? getCurrentDate()
  //   : `${selectedTerm.startDate}-07-01`;

  const reset = () => {
    setMovedItems([]);
    setIsCheckedPriorYearGroup(false);
    setIsAvailableTermClicked(false);
  };

  // const onChangeTerm = (selectedTerm: TermToAssign) => {
  //   reset();
  //   setClubsData([]);
  //   setSelectedTerm(selectedTerm);
  // };

  // const optionSelectHandler = (selectedTerm: TermToAssign) => {
  //   setIsAvailableTermClicked(true);
  //   setChangedSelectedTerm(selectedTerm);
  //   if (areClubsMoved) {
  //     setIsOpenedAvailableTermModal?.(true);
  //   } else {
  //     onChangeTerm(selectedTerm);
  //   }
  // };

  const getUpdatedData = (updatedData: Group[]) => {
    const items = getFormattedData(
      [...clubsData, ...prevClubsData],
      updatedData
    );
    setMovedItems(items);
  };

  const cancel = () => {
    globalHide();
    localizedNavigate(agsPageUrl);
  };

  const modalOnCancelHandler = () => {
    show(true);
  };

  const save = async () => {
    try {
      if (movedItems.length) {
        const destination: Destination = {
          districtPk: districtID,
          assistantGovernors: [],
          clubReplace: [],
        };

        movedItems.forEach(movedItem => {
          if (movedItem.destinationId && movedItem.clubId) {
            destination.assistantGovernors.push({
              individualPk: movedItem.destinationId,
              clubs: [
                {
                  clubId: movedItem.clubId,
                  action: 'Add',
                },
              ],
            });
          }
        });

        const destinationWithoutKey = _.omit(destination, 'destination');

        const status = await clubAssignmentsForAssistantGovernors(
          destinationWithoutKey
        );
        if (status) {
          addSuccess(
            t(
              'manage-club-assignments.save-assigned-clubs.success',
              'Success.'
            ),
            { id: 'form.success' }
          );
          localizedNavigate(agsPageUrl);
        }
      }
    } catch (error) {
      addError((error as Error).message, { id: 'form.error' });
    }
  };

  const savePriorYearModal = () => {
    setMovedItems([]);
    setIsCheckedPriorYearGroup(!isCheckedPriorYearGroup);
    setIsOpenedPriorYearModal(false);
  };

  const saveAvailableTermModal = () => {
    setMovedItems([]);
    // setSelectedTerm(changedSelectedTerm);
    setIsOpenedAvailableTermModal(false);
  };

  const closePriorYearModal = () => setIsOpenedPriorYearModal(false);

  const closeAvailableTermModal = () => setIsOpenedAvailableTermModal(false);

  const getSelectTermComponent = () => {
    return (
      <div className="mb-5 font-bold text-small">
        {t('select-rotary-year.term', 'Term')}{' '}
        {getTermStringFromObject(termsToAssign[0])}
      </div>
    );
    // termsToAssign.length > 1 ? (
    //   <SelectTerm
    //     termsToAssign={termsToAssign}
    //     selectedTerm={selectedTerm}
    //     onChangeTerm={optionSelectHandler}
    //   />
    // ) : (
    //   <div className="mb-5 font-bold text-small">
    //     {t('select-rotary-year.term', 'Term')}{' '}
    //     {getTermStringFromObject(termsToAssign[0])}
    //   </div>
    // );
  };

  const clubAssignmentsComponent = (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-members',
          '{{prefix}} District members {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="mt-6">
        {!isShowStepPage && (
          <LinkPrevious
            path={agsPageUrl}
            showModal={() => show(true)}
            label={t('back-link-text', 'Back')}
          />
        )}
        <Title className="text-xl m-0 leading-9 mb-6 tablet:mb-0">
          {t('manage-club-assignments', 'Manage Club Assignments')}
        </Title>
        {getSelectTermComponent()}
        <div className="text-base mt-4 mb-14 hidden tablet:block">
          {t(
            'manage-club-assignments.subheading',
            'Update and Change Club assignments using Drag and drop from the lists below'
          )}
        </div>
        {!!clubsData.length && (
          <DragAndDropContainers
            isCheckedPriorYearGroup={isCheckedPriorYearGroup}
            areClubsMoved={areClubsMoved}
            setIsPriorYearClicked={setIsPriorYearClicked}
            setIsOpenedPriorYearModal={setIsOpenedPriorYearModal}
            setIsCheckedPriorYearGroup={setIsCheckedPriorYearGroup}
            isSaveDisabled={moveLoading}
            getUpdatedData={getUpdatedData}
            data={clubsData}
            prevData={prevClubsData}
            reset={reset}
            save={save}
            cancel={modalOnCancelHandler}
          />
        )}
      </div>

      <SaveChangesModal
        isOpen={isShowing}
        onClose={globalHide}
        onSave={save}
        onContinue={cancel}
      />

      {isAvailableTermClicked && areClubsMoved && (
        <SaveChangesModal
          isOpen={isOpenedAvailableTermModal}
          onClose={closeAvailableTermModal}
          onSave={saveAvailableTermModal}
          onContinue={closeAvailableTermModal}
          textContent={
            getLabelValue(
              getClubAssignmentsModalLabels(t),
              AVAILABLE_TERMS_MODAL
            )?.textContent
          }
        />
      )}

      {isPriorYearClicked && areClubsMoved && (
        <SaveChangesModal
          isOpen={isOpenedPriorYearModal}
          onClose={closePriorYearModal}
          onSave={savePriorYearModal}
          onContinue={closePriorYearModal}
          textContent={
            getLabelValue(getClubAssignmentsModalLabels(t), PRIOR_YEAR_MODAL)
              ?.textContent
          }
          isExtendedHeight
        />
      )}
    </>
  );

  return isShowStepPage ? (
    <PageSteps
      className="p-0"
      backBtnClassName="inline-flex items-center font-bold text-bright-blue-600 text-xs leading-xs-heading"
      backHandler={modalBackHandler}
      step={current || 0}
      total={total || 0}
      customBackBtnTitle={t(
        'page-steps.back-to-ags-button',
        'Back to Assistant Governors'
      )}
    >
      {clubAssignmentsComponent}
    </PageSteps>
  ) : (
    clubAssignmentsComponent
  );
};

export default ClubAssignments;
